.socialMedia {
  padding-top: 30px;
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.socialMedia__links {
  padding: 11px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  background: #ffffff;

  cursor: pointer;
  text-decoration: none;
}

.socialMedia__title {
  font-family: RalewayBold;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  /* line-height: 21px; */
  font-feature-settings: "pnum" on, "lnum" on;
  color: #40404f;
}

.socailMedia__icon {
  border-radius: 14px;
}

@media screen and (max-width: 480px) {
  .socialMedia__links {
    flex-flow: row-reverse;
    justify-content: start;
    gap: 16px;
  }
  .socialMedia__title {
    font-family: RalewaySemiBold;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  .socialMedia__title span {
    display: none;
  }
}
