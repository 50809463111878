.App__content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  max-width: 1110px;
  margin: auto;
  width: 100%;
}

.content {
  flex: 1 1 auto;
  margin-top: 80px;
}
.content__block {
  margin-top: 30px;
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
}
.content__block_first {
  margin-top: 40px;
  border-radius: 16px;
  background: #fff;
}
.content__block_big {
  margin-top: 32px;
  border-radius: 0px;
  background: #fff;
}

.blockTitle {
  font-family: RalewayBold;
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #40404f;
}

@media screen and (max-width: 480px) {
  .blockTitle {
    font-size: 18px;
  }
  .content {
    margin-top: 60px;
  }
  .content__block {
    margin-top: 20px;
  }

  .content__block_first {
    margin-top: 20px;
  }

  .socialMedia {
    padding-top: 20px !important;
  }
}

.copy {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10.5px;
  border-radius: 6px;
  height: 22px;
  font-family: "Raleway";
  position: relative;
}

.copy .copy__copytext {
  opacity: 0;
  visibility: hidden;
  transition: 0.15s ease-out 0ms;
  width: 0px;
  left: 0px;
  position: absolute;
  padding: 5px 10.5px;
  border-radius: 6px;
  height: 22px;
  background: #f4f6f8;
  display: flex;
  align-items: center;
  z-index: 2;
}

.copy:hover .copy__copytext {
  opacity: 1;
  visibility: visible;
  width: 90px;
  left: -80px;
}

.copy__icon {
  z-index: 2;
}

.copy__modal {
  width: 142px;
  height: 51px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 6px 36px rgba(0, 0, 0, 0.16);
  border-radius: 16px;

  font-family: RalewaySemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  font-feature-settings: "pnum" on, "lnum" on;

  position: fixed;
  right: 1em;
  bottom: 1em;
  opacity: 0;
  z-index: 1;
}

.copy__modal_active {
  animation: CopyNotification 1s;
  z-index: 1;
}

@keyframes CopyNotification {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  50% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

.field {
  background: #f4f6f8;
  border-radius: 8px;
  padding: 6px 10px;
  font-size: 12px;
  box-sizing: border-box;

  display: flex;
  gap: 14px;
  justify-content: space-between;
  border: 2px solid #f4f6f8;
  transition: 0.25s ease-out 50ms;
  align-items: center;
}

.field:hover {
  background: #fff;
}
.field:hover .type__title {
  color: var(--field-font-color);
}
.field:hover .copy {
  background: #f4f6f8;
}

.info__type {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Raleway;
}

.type__title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  font-feature-settings: "pnum" on, "lnum" on;
  color: #8d96a3;
}

.type__value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #40404f;
  font-feature-settings: "pnum" on, "lnum" on;
}

.field:hover .copy__icon path {
  fill: var(--field-font-color);
}
.copy:hover .info__btn path {
  fill: var(--field-font-color);
}

@media screen and (max-width: 1140px) {
  .container {
    max-width: 95%;
  }
}

@media screen and (max-width: 424px) {
  .contacts__info {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  }
  .request__form input {
    min-width: auto;
  }
}

.border-outline:hover {
  border: 1px solid var(--primary-color);
  background: white;
  color: var(--primary-color);
}
.outline:hover {
  background: white;
  color: var(--light-primary-color);
}
