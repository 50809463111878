.organizationBlock {
  cursor: pointer;
}
.organizationBlock__header {
  display: grid;
  grid-template-columns: 10fr 2fr 0fr;
  gap: 20px;
  align-items: center;
}

.noFile {
  grid-template-columns: 10fr 0fr;
}

.organizationBlock__icon {
  cursor: pointer;
}
.organizationBlock__rotate {
  transform: rotate(180deg);
}

details[open] {
  animation: detailsAnimate 0.25s ease-in-out;
}

@keyframes detailsAnimate {
  0% {
    margin-top: -20px;
    opacity: 0;
  }
  100% {
    margin-top: 0px;
    opacity: 1;
  }
}

.organizationBlock summary {
  display: none;
}
.organizationInfo__info {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 16px;
}

.organizationInfo__tax {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.organizationInfo__item .type__value {
  font-weight: normal;
}

.exchangeForEDO__info {
  margin-top: 16px;
}

.maps .type__value {
  color: var(--light-primary-color);
  text-decoration: underline;
  cursor: pointer;
}

.info__maps {
  display: flex;
  align-items: center;
  gap: 15px;
}

.info__maps .type__value {
  font-weight: 600;
  color: #2d9cdb;
}

@media screen and (max-width: 1139px) {
  .organizationInfo__info {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 480px) {
  .organizationBlock__header {
    grid-template-columns: 10fr 0fr;
  }

  .organizationBlock__icon {
    grid-column-start: 2;
    grid-row-start: 1;
  }

  .requisitesFileBtn {
    grid-column-end: 3;
    grid-column-start: 1;
  }
}

.headerBtns {
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 205px;
  justify-content: flex-end;
}

.requisitesFileBtn {
  border-radius: 6px;
  line-height: 16px;
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  transition: 0.15s ease-out;
  height: 51px;
  box-sizing: border-box;
  font-family: Raleway;
  gap: 10px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  stroke: var(--primary-color);
}

.requisitesFile__title {
  font-weight: 600;
  font-size: 14px;
}

.requisitesFileBtn:hover {
  color: white;
  border: white;
  stroke: white;
  background: var(--primary-color);
  border: 1px solid;
}

.requisitesFileBtn:hover .requisitesFile__icon {
  stroke: white;
  transition: 0.15s ease-out;
}

.blockTitle__organizationOwnership {
  color: #bdbdbd;
}

.organizationBlock__title h3 {
  font-size: inherit;
  margin: 0;
}

.blockTitle__organizationName .copy {
  vertical-align: bottom;
  display: inline-flex;
  padding: 0px 10.5px;
}

@media screen and (max-width: 480px) {
  .blockTitle__organizationOwnership {
    font-size: 14px;
  }
  .blockTitle__organizationName {
    font-size: 14px;
  }
}
