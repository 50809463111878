.bankAccountInfo__info {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  padding-top: 16px;
}

.bankAccountInfo__item_number-1 {
  grid-column-start: 1;
  grid-column-end: 3;
}
.bankAccountInfo__item_number-2 {
  grid-column-start: 3;
  grid-column-end: 5;
}
.bankAccountInfo__item_number-3 {
  grid-column-start: 5;
  grid-column-end: 7;
}
.bankAccountInfo__item_number-4 {
  grid-column-start: 1;
  grid-column-end: 4;
}
.bankAccountInfo__item_number-5 {
  grid-column-start: 4;
  grid-column-end: 7;
}

@media screen and (max-width: 780px) {
  .bankAccountInfo__info {
    grid-template-columns: 1fr;
  }
  .bankAccountInfo__item_number-1,
  .bankAccountInfo__item_number-2,
  .bankAccountInfo__item_number-3,
  .bankAccountInfo__item_number-4,
  .bankAccountInfo__item_number-5 {
    grid-column-start: auto;
    grid-column-end: auto;
  }
}
