.request {
  background: var(--primary-color);
  height: 80px;
  box-shadow: 0px 8px 36px var(--shadow-color);
  border-radius: 16px;

  color: var(--primary-font-color);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request__title {
  font-family: RalewaySemiBold;
}
.request__title h3 {
  margin: 0;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
}
.request__form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.request__form input {
  padding: 14.5px 12px;
  outline: none;
  border: 0px;
  border-radius: 8px;
  min-width: calc(290px - 12px * 2);

  font-family: Raleway;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "pnum" on, "lnum" on;
}
.request__form button {
  background: var(--light-primary-color);
  border: 0px;
  border-radius: 6px;
  color: var(--primary-font-color);
  min-width: calc(290px - 12px * 2);
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: "pnum" on, "lnum" on;
  cursor: pointer;
  font-family: Raleway;
  font-weight: 600;
  transition: 0.15s ease-out;
}

.requestModal {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000085;

  z-index: 3;
}

.requestModal__form {
  background: #fff;
  z-index: 1;
  color: #40404f;
  width: 370px;
  height: 215px;

  text-align: center;
  border-radius: 8px;
}
.form__text-block {
  padding: 24px;
  font-family: Raleway;
}
.text-block__title {
  margin: 0;
  margin-bottom: 24px;
  font-family: RalewayBold;
}

.form__btn-block {
  padding: 25px;
  border-top: 1px solid #dbdbdb;
}
.form__btn {
  width: 290px;
  height: 48px;

  background: var(--primary-color);
  border-radius: 6px;
  padding: 16px;

  border: 0px;

  color: white;

  cursor: pointer;
}

@media screen and (max-width: 934px) {
  .request {
    flex-direction: column;
    height: 100%;
    gap: 10px;
    align-items: normal;
  }
}

@media screen and (max-width: 669px) {
  .request {
    flex-direction: column;
    height: 100%;
    gap: 10px;
  }
  .request__form {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    width: inherit;
  }
}

@media screen and (max-width: 320px) {
  .request__form button {
    min-width: 0;
  }
  .request__form input {
    min-width: 0;
  }
}
