.lookProducts {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lookProducts__link {
  width: 290px;
  height: 46px;

  background: var(--primary-color);
  border: 1px solid;
  border-radius: 6px;
  line-height: 16px;
  text-decoration: none;
  color: var(--primary-font-color);
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s ease-out;
  font-family: "Raleway";
}

@media screen and (max-width: 480px) {
  .lookProducts {
    flex-direction: column;
    gap: 14px;
  }
  .lookProducts__link {
    width: 100%;
  }
}

.lookProducts__titleBlock {
  display: flex;
  align-items: center;
  gap: 16px;
}

.lookProducts__icon path {
  fill: var(--primary-color);
}
