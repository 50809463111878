.header {
  height: 80px;
  background: var(--primary-color);
  display: flex;
  position: fixed;
  width: 100vw;
  z-index: 3;
  font-family: Raleway;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
.header__container > div {
  flex: 1;
}
.header__rightMenu {
  justify-content: flex-end;
}
.menu {
  display: flex;
  gap: 1.5em;
}
.menu__link {
  text-decoration: none;
  color: var(--primary-font-color);
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: "pnum" on, "lnum" on;
}

.header__logo {
  padding: 11px 27px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  z-index: 2;
}
.header__logoTemplate {
  display: flex;
  justify-content: center;
}
.header__logo_true {
  height: auto;
  width: auto;
  padding: 0;
  background: none;
  display: flex;
}

.header__logo_true img {
  object-fit: contain;
}

.logo__text {
  margin: 0;
  color: white;
  font-family: Pacifico;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 47px;
}

.mobileMenu {
  position: relative;
  display: none;
}
/* Кнопка меню для мобилки */
.mobileMenu__btn {
  display: block;
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.mobileMenu__btn span,
.mobileMenu__btn span::before,
.mobileMenu__btn span::after {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 2px;
  background-color: var(--primary-font-color);
}
.mobileMenu__btn span::before,
.mobileMenu__btn span::after {
  content: "";
  display: block;
  transition: 0.1s;
}
.mobileMenu__btn span::before {
  transform: translateY(-5px);
}
.mobileMenu__btn span::after {
  transform: translateY(5px);
}

.mobileMenu__btn_active span {
  height: 0;
}

.mobileMenu__btn_active span:before {
  transform: rotate(-45deg);
}
.mobileMenu__btn_active span:after {
  transform: rotate(45deg);
}

/*  Nav mobile */

.mobileMenu__nav {
  display: none;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  justify-content: center;
  padding: 15px;
  gap: 16px;
  box-sizing: border-box;

  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
}

.mobileMenu__link {
  text-decoration: none;
  color: var(--primary-font-color);
}

.mobileMenu__nav_active {
  display: flex;
  opacity: 1;
  animation: menuShow 0.3s ease-in;
  background: var(--primary-color);
  overflow-y: hidden;
}

@keyframes menuShow {
  0% {
    opacity: 0;
    transform: translateY(calc(-100vh - 50%));
  }
  100% {
    opacity: 1;
    transform: translateY(calc(100vh- 50%));
  }
}

@media screen and (max-width: 906px) {
  .menu {
    gap: 0.5em;
  }
}
@media screen and (max-width: 854px) {
  .header__rightMenu {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media screen and (max-width: 768px) {
  .mobileMenu {
    display: block;
  }
  .menu {
    display: none;
  }
  .header__logo img {
    width: auto;
  }
  .header__mobileMenu {
    display: flex;
    justify-content: flex-end;
  }
}

.logo__img {
  display: none;
}

.logo__default {
  max-height: 56px;
  display: block;
}

.logo__square {
  max-height: 68px;
  display: block;
}
@media screen and (max-width: 480px) {
  .header {
    height: 60px;
  }

  .logo__default {
    max-height: 36px;
  }

  .logo__square {
    max-height: 55px;
  }
}
