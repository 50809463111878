.footer {
  margin-top: 40px;
  color: #fff;
  text-align: center;
  font-family: Raleway;
}
.footer__socialMedia {
  background: #36393f;
  padding: 30px;
}

.footerSocialMedia__icons {
  padding-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.footerIcon__container {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2b2e33;
  border-radius: 8px;
  cursor: pointer;
}

.footer__bottom {
  background: #2b2e33;
  padding: 16px;
  font-size: 14px;
  font-feature-settings: "pnum" on, "lnum" on;
  line-height: 16px;
  cursor: pointer;
}
.footerBottom__serviceIcon {
  margin-top: 13px;
}

.footerBottom__template:hover {
  color: rgb(255, 101, 83);
}

.footerBottom__template:hover svg rect,
.footerBottom__template:hover svg path {
  fill: rgb(255, 101, 83);
}
.footerBottom__template:hover svg path:last-child {
  fill: rgb(255, 255, 255);
}

@media screen and (max-width: 480px) {
  .footer__bottom {
    font-size: 12px;
  }
}
