.promo__image {
  height: 200px;
  /* background: url('../../assets/img/promoBackground.png'); */
  display: flex;
  align-items: center;
  justify-self: start;
  background-size: cover;
  padding: 0px 36px;
  border-radius: 8px 8px 0px 0px;
  border: none;
}

.promo__title {
  min-height: 60px;
  padding: 16px;
  font-family: RalewaySemiBold;
  font-style: normal;

  font-size: 24px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #40404f;

  display: flex;
  align-items: center;
  gap: 5px;

  justify-content: center;
  word-break: break-word;
}

.promo__title span {
  line-height: 3px;
}
.promo__image_mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .promo__image_desktop {
    display: none;
  }
  .promo__image_mobile {
    display: block;
  }
}
