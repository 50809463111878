@font-face {
  font-family: Pacifico;
  src: url("./fonts/Pacifico.ttf");
}
@font-face {
  font-family: Raleway;
  src: url("./fonts/Raleway.ttf");
}

@font-face {
  font-family: RalewayBold;
  src: url("./fonts/Raleway-Bold.ttf");
}

@font-face {
  font-family: RalewayMedium;
  src: url("./fonts/Raleway-Medium.ttf");
}
@font-face {
  font-family: RalewaySemiBold;
  src: url("./fonts/Raleway-SemiBold.ttf");
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #e5e5e5;
}
