.contacts__info {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  padding-top: 16px;
}

.contact__info {
  display: flex;
  align-items: center;
  position: relative;
}
.info__btn {
  cursor: pointer;
}
.info__modal {
  position: absolute;
  bottom: 35px;

  width: 350px;

  background: #ffffff;
  box-shadow: 0px 6px 36px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  z-index: 1;

  display: flex;

  font-family: RalewaySemiBold;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: "pnum" on, "lnum" on;
  padding: 16px;
}

.contact__type {
  display: flex;
  flex-direction: column;
}

.contact .type__value {
  font-weight: 600;
  color: #2d9cdb;
  text-decoration-line: underline;
}

.copy:hover .copy__icon path {
  fill: var(--light-primary-color);
}
.info__btn:hover path {
  fill: var(--light-primary-color);
}
